<template>
  <span
    v-if="country"
    :class="flagIconClass"
    :style="{ backgroundImage: flagSvg }"
  ></span>
</template>

<script>
import flagEE from '~/assets/flags/ee.svg';
import flagPL from '~/assets/flags/pl.svg';
import flagRU from '~/assets/flags/ru.svg';
import flagEN from '~/assets/flags/gb.svg';
import roundedFlagEE from '~/assets/flags/1x1/ee.svg';
import roundedFlagPL from '~/assets/flags/1x1/pl.svg';
import roundedFlagRU from '~/assets/flags/1x1/ru.svg';
import roundedFlagEN from '~/assets/flags/1x1/gb.svg';

export default {
  name: 'RFlag',
  props: {
    country: {
      type: String,
      required: true,
    },
    rounded: {
      type: Boolean,
      default: false,
    },
    roundedFull: {
      type: Boolean,
      default: false,
    },
    shadow: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      validator: function (value) {
        return value === 'small' || value === 'normal' || value === 'big';
      },
      default: 'normal',
    },
  },
  computed: {
    flagSvg() {
      if (this.roundedFull) {
        switch (this.country.toLowerCase()) {
          case 'ee':
            return `url("${roundedFlagEE}")`;
          case 'pl':
            return `url("${roundedFlagPL}")`;
          case 'ru':
            return `url("${roundedFlagRU}")`;
          case 'en':
            return `url("${roundedFlagEN}")`;
          default:
            return null;
        }
      } else {
        switch (this.country.toLowerCase()) {
          case 'ee':
            return `url("${flagEE}")`;
          case 'pl':
            return `url("${flagPL}")`;
          case 'ru':
            return `url("${flagRU}")`;
          case 'en':
            return `url("${flagEN}")`;
          default:
            return null;
        }
      }
    },
    flagIconClass() {
      return {
        [`flag`]: true,
        [`rounded`]: this.rounded,
        [`rounded-full`]: this.roundedFull,
        [`shadow`]: this.shadow,
        [this.flagMargin]: true,
      };
    },
    flagMargin() {
      switch (this.size) {
        case 'small':
          return 'small-flag';
        case 'normal':
          return 'normal-flag';
        case 'big':
          return 'big-flag';
        default:
          return 'normal-flag';
      }
    },
  },
};
</script>
<style scoped>
.flag {
  width: 52px;
  height: 39px;
  display: inline-block;
}

.flag.rounded-full {
  width: 39px;
  height: 39px;
  display: inline-block;
}

.small-flag {
  transform: scale(0.25);
  -ms-transform: scale(0.25);
  -webkit-transform: scale(0.25);
  -moz-transform: scale(0.25);
}

.normal-flag {
  transform: scale(0.5);
  -ms-transform: scale(0.5);
  -webkit-transform: scale(0.5);
  -moz-transform: scale(0.5);
}

.big-flag {
  margin: 0;
}

.small-flag.rounded {
  -moz-border-radius: 15px;
  border-radius: 15px;
}

.normal-flag.rounded {
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.big-flag.rounded {
  -moz-border-radius: 8px;
  border-radius: 8px;
}

.small-flag.rounded-full {
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.normal-flag.rounded-full {
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.big-flag.rounded-full {
  -moz-border-radius: 50%;
  border-radius: 50%;
}

.small-flag.shadow {
  -webkit-box-shadow:
    0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%),
    0px 1px 10px 0px rgb(0 0 0 / 12%);
  -moz-box-shadow:
    0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%),
    0px 1px 10px 0px rgb(0 0 0 / 12%);
  box-shadow:
    0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%),
    0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.normal-flag.shadow {
  -webkit-box-shadow:
    0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%),
    0px 1px 10px 0px rgb(0 0 0 / 12%);
  -moz-box-shadow:
    0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%),
    0px 1px 10px 0px rgb(0 0 0 / 12%);
  box-shadow:
    0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%),
    0px 1px 10px 0px rgb(0 0 0 / 12%);
}

.big-flag.shadow {
  -webkit-box-shadow:
    0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%),
    0px 1px 10px 0px rgb(0 0 0 / 12%);
  -moz-box-shadow:
    0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%),
    0px 1px 10px 0px rgb(0 0 0 / 12%);
  box-shadow:
    0px 2px 4px -1px rgb(0 0 0 / 20%),
    0px 4px 5px 0px rgb(0 0 0 / 14%),
    0px 1px 10px 0px rgb(0 0 0 / 12%);
}
</style>
